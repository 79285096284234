import LocomotiveScroll from 'locomotive-scroll';

const scroll = new LocomotiveScroll({
    el: document.querySelector('[data-scroll-container]'),
    smooth: true,
    mobile: {
        smooth: true
    },
    tablet: {
        smooth: true
    },
    multiplier: 0.8,
    firefoxMultiplier: 1,
    reloadOnContextChange: true
});

let img = document.querySelector('.home-section-2__col-2 img');

scroll.on('scroll', (args) => {
    let { scroll } = args;
    let transformValue = (2.5 * (scroll.y / 1500) < 1) ? 1 : 2.5 * (scroll.y / 1500);
    img.style.transform = "scale(" + transformValue + ")";
})